import {Expose} from 'class-transformer';
import {AccountModel} from './account.model';

export class CreateAccountModel extends AccountModel {

  @Expose()
  username: string = '';

  @Expose()
  password: string = '';

  @Expose()
  repeatPassword: string = '';
}

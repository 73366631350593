import {LanguageModel} from './models/language.model';

export class Utils {
  static availableLangs: LanguageModel[] = [
    {
      code: 'fr-FR',
      name: 'Français',
      flag: 'assets/flags/fr.svg'
    },
    {
      code: 'en-US',
      name: 'English',
      flag: 'assets/flags/us.svg'
    },
    {
      code: 'es-ES',
      name: 'Español',
      flag: 'assets/flags/es.svg'
    },
    {
      code: 'de-DE',
      name: 'Deutsch',
      flag: 'assets/flags/de.svg'
    },
  ];
}

<div>
  <div class="page-title" translate>home.title</div>
  <div class="mt-5 flex flex-wrap align-items-center">
    <div class="title-2 mr-5">{{ nbTradsEnCours }} traductions en cours</div>
    <div class="flex flex-grow-1 justify-content-end">
      <p-button icon="pi pi-plus" [label]="'home.btn-new'|translate"
                styleClass="p-button-sm p-button-rounded"
                (click)="createNew()"></p-button>
    </div>
  </div>
  <div class="flex flex-wrap mt-5">
    <div class="flex-grow-1">

      <div class="flex justify-content-end">
        <span class="p-float-label p-inputwrapper-filled mr-2">
          <input id="title" type="text" class="p-filled w-full" pInputText name="title"
                 [(ngModel)]="searchText" (ngModelChange)="updateSearch()"/>
          <label for="title" translate>home.filters.title</label>
        </span>
        <span class="p-float-label">
          <p-multiSelect
            id="cmbstatus"
            styleClass="p-multiselect-sm"
            [options]="statuses"
            [(ngModel)]="selectedStatuses"
            optionLabel="name"
            (onChange)="updateSearch()"
          ></p-multiSelect>
          <label for="cmbstatus" translate>home.filters.statuses</label>
        </span>
      </div>

      <p-dataView #dv [value]="translationsFiltered" [layout]="'grid'">
        <ng-template let-product pTemplate="gridItem">

          <div class="col-12 sm:col-12 lg:col-4 xl:col-4 p-2 align-items-stretch">
            <div class="translation-item p-3 h-full cursor-pointer" (click)="goTo(product)">
              <div class="flex flex-column h-full">
                <div class="title-2 traduction-title  flex-grow-0">{{ product.title }}</div>
                <div class="mt-5 flex-grow-0">
                  <b translate>show-translation.date</b> {{ product.createdAt|amTimeAgo }}<br/>
                  <b translate>show-translation.destination</b> {{ product.destination }}<br/>
                </div>
                <div class="mt-4 mb-4 flex-grow-1">
                  {{ product.context }}
                </div>
                <div class="flex-grow-0 flex justify-content-end">
                  <p-button [label]="('translation.' + product.status)|translate"
                            styleClass="p-button-sm p-button-rounded p-button-outlined"
                  ></p-button>
                </div>
              </div>
            </div>
          </div>
          <!--
          <div class="col-12 sm:col-6 lg:col-12 xl:col-4 p-2">
            <div class="p-4 border-1 surface-border surface-card border-round">
              <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                    <span class="flex align-items-center gap-2">
                        <i class="pi pi-tag"></i>
                        <span class="font-semibold">{{ product.category }}</span>
                    </span>
              </div>
              <div class="flex flex-column align-items-center gap-3 py-5">
                <img class="w-9 shadow-2 border-round"
                     [src]="'https://primefaces.org/cdn/primeng/images/demo/product/' + product.image"
                     [alt]="product.name"/>
                <div class="text-2xl font-bold">{{ product.name }}</div>
                <p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating>
              </div>
              <div class="flex align-items-center justify-content-between">
                <span class="text-2xl font-semibold">{{ '$' + product.price }}</span>
                <button pButton icon="pi pi-shopping-cart" class="p-button-rounded"
                        [disabled]="product.inventoryStatus === 'OUTOFSTOCK'"></button>
              </div>
            </div>
          </div>


          -->
        </ng-template>
      </p-dataView>
    </div>
  </div>
</div>
<p-sidebar [(visible)]="newSidebar" position="right"
           styleClass="sidebar-translation {{ fullSize ? 'sidebar-translation-full' : '' }}"
           [showCloseIcon]="false">
  <ng-template pTemplate="header"></ng-template>
  <div class="flex flex-column h-full">
    <div class="flex-grow-1">
      <app-translation-crud [translation]="translation" (cancel)="newSidebar = false"
                            [(fullsize)]="fullSize"
                            (saved)="translationCreated($event)"></app-translation-crud>
    </div>
  </div>
</p-sidebar>

<div class="sticky">
  <p-button [label]="'account.btn-back'|translate" icon="pi pi-arrow-left"
            styleClass="p-button-text mr-3" (click)="back()"></p-button>


  <div class="page-title mb-5" translate>account.title</div>
</div>

<div class="flex justify-content-center">
  <p-card [header]="'account.info.title'|translate" class="w-full md:w-7 border-round-xl">
    <ng-container *ngIf="user">
      <ng-container *ngFor="let f of fields">
        <div class="field grid">
          <label [for]="f.label" class="col-12 md:col-3 mb-2 md:mb-0" translate>{{ f.label }}</label>
          <div class="col-12 md:col-9">
            <input pInputText id="name" class="p-inputtext-sm w-full" type="text"
                   [(ngModel)]="userToEdit[f.key]"
                   [class.border-transparent]="!isEditing"
                   [disabled]="isLoading" [readonly]="!isEditing">
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!isEditing">
      <p-button [label]="'account.btn-edit'|translate" styleClass="p-button-text mr-3" (click)="edit()"></p-button>
    </ng-container>
    <ng-container *ngIf="isEditing">
      <p-button [label]="'account.btn-cancel'|translate" styleClass="p-button-text mr-3"
                (click)="cancel()"></p-button>
      <p-button [label]="'account.btn-save'|translate"
                styleClass="p-button-sm p-button-rounded" [icon]="isLoading ? 'pi pi-spin pi-spinner' : ''"
                (click)="save()"
      ></p-button>
    </ng-container>
  </p-card>
</div>
<div class="flex justify-content-center mt-8">

  <p-card [header]="'account.bills.title'|translate" class="w-full md:w-7 border-round-xl">
    <ng-container *ngFor="let b of bills">
      <p-button [label]="b.ref + ' - ' + (b.dateValidation|amDateFormat:'LL')"
                (click)="getBill(b)"
                styleClass="p-button-text  w-full" icon="pi pi-arrow-right" iconPos="right"></p-button>
    </ng-container>
  </p-card>
</div>

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {LoginComponent} from './pages/auth/login/login.component';
import {LayoutComponent} from './layout/layout/layout.component';
import {TranslationComponent} from './pages/translation/translation.component';
import {PaymentComponent} from './pages/payment/payment.component';
import {AccountComponent} from './pages/account/account.component';
import {LayoutAuthComponent} from './layout/layout-auth/layout-auth.component';
import {CreateAccountComponent} from './pages/auth/create-account/create-account.component';
import {ActivationComponent} from './pages/auth/activation/activation.component';

const routes: Routes = [
  {
    path: 'auth',
    component: LayoutAuthComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'create-account',
        component: CreateAccountComponent
      },
      {
        path: 'activation/:email',
        component: ActivationComponent
      },
    ]
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        data: {
          hideMenu: false
        }
      },
      {
        path: 'account',
        component: AccountComponent,
        data: {
          hideMenu: false
        }
      },
      {
        path: ':id',
        component: TranslationComponent,
        data: {
          hideMenu: true
        }
      },
      {
        path: ':id/payment/:status',
        component: PaymentComponent,
        data: {
          hideMenu: false
        }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import {Expose} from 'class-transformer';

export class AccountModel {

  @Expose()
  address: string = '';

  @Expose()
  zip: string = '';

  @Expose()
  town: string = '';

  @Expose()
  email: string = '';

  @Expose()
  name: string = '';

  @Expose()
  lastname: string = '';
}

import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './pages/home/home.component';
import {MomentModule} from 'ngx-moment';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import * as moment from 'moment';
import {FormsModule} from '@angular/forms';
import {CarouselModule} from 'primeng/carousel';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {LoginComponent} from './pages/auth/login/login.component';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {ToastModule} from 'primeng/toast';
import {ConfirmationService, MessageService} from 'primeng/api';
import {LayoutComponent} from './layout/layout/layout.component';
import {SidebarModule} from 'primeng/sidebar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AvatarModule} from 'primeng/avatar';
import {RippleModule} from 'primeng/ripple';
import {TranslationCrudComponent} from './components/translation-crud/translation-crud.component';
import {DropdownModule} from 'primeng/dropdown';
import {EditorModule} from 'primeng/editor';
import {DialogModule} from 'primeng/dialog';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import {ErrorDialogComponent} from './components/error-dialog/error-dialog.component';
import {TranslationComponent} from './pages/translation/translation.component';
import {SkeletonModule} from 'primeng/skeleton';
import {DataViewModule} from 'primeng/dataview';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {PaymentComponent} from './pages/payment/payment.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {AccountComponent} from './pages/account/account.component';
import {CardModule} from 'primeng/card';
import {LayoutAuthComponent} from './layout/layout-auth/layout-auth.component';
import {CreateAccountComponent} from './pages/auth/create-account/create-account.component';
import {PasswordModule} from 'primeng/password';
import {ActivationComponent} from './pages/auth/activation/activation.component';
import {SelectButtonModule} from 'primeng/selectbutton';
import {FileUploadModule} from 'primeng/fileupload';
import {NgxSummernoteModule} from 'ngx-summernote';
import {TrViewerComponent} from '../assets/images/components/tr-viewer/tr-viewer.component';
import {SpinnerModule} from 'primeng/spinner';
import {MultiSelectModule} from 'primeng/multiselect';
import {Utils} from './utils';
import {LanguageModel} from './models/language.model';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function getCurrentLanguage(): LanguageModel {
  let language: any = localStorage.getItem('language');
  if (!language) {
    language = Utils.availableLangs[0];
    localStorage.setItem('language', JSON.stringify(language));
  } else {
    language = JSON.parse(language);
  }
  return language;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SafeHtmlPipe,
    LoginComponent,
    CreateAccountComponent,
    ActivationComponent,
    LayoutComponent,
    LayoutAuthComponent,
    TranslationCrudComponent,
    TrViewerComponent,
    ErrorDialogComponent,
    TranslationComponent,
    PaymentComponent,
    AccountComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: getCurrentLanguage().code,
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    MomentModule,
    FormsModule,
    CarouselModule,
    InputTextModule,
    ButtonModule,
    ToastModule,
    SidebarModule,
    AvatarModule,
    RippleModule,
    DropdownModule,
    EditorModule,
    DialogModule,
    DynamicDialogModule,
    SkeletonModule,
    DataViewModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    CardModule,
    PasswordModule,
    SelectButtonModule,
    FileUploadModule,
    NgxSummernoteModule,
    SpinnerModule,
    MultiSelectModule,
  ],
  providers: [
    MessageService,
    DialogService,
    ConfirmationService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

moment.locale('fr');

import {Injectable} from '@angular/core';
import {instanceToPlain, plainToInstance} from 'class-transformer';
import {ApiService} from './api.service';
import {environment} from '../../environments/environment';
import {CountryModel} from '../models/country.model';
import {QuoteModel} from '../models/auth/quote.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  static DATA_DICO: any = {};

  constructor(private readonly api: ApiService) {
  }

  async initData() {
    const results = await this.api.get(environment.baseApi + '/api/auth/data/all', null);
    DataService.DATA_DICO['countries'] = results.countries.map((value: any) => plainToInstance(CountryModel, value))
  }


  async getData(type: string): Promise<any> {
    if (DataService.DATA_DICO[type]) {
      return DataService.DATA_DICO[type];
    }

    await this.initData();

    return DataService.DATA_DICO[type];
  }

  async getRandomQuote() {
    return this.api.get(environment.baseApi + '/api/auth/data/quote', {
      isArray: false,
      class: QuoteModel
    })
  }
}

<p-button [label]="'show-translation.btn-back'|translate" icon="pi pi-arrow-left"
          styleClass="p-button-text mr-3" (click)="back()"></p-button>
<div *ngIf="isDataLoading">
  <p-skeleton height="46px" styleClass="mb-2"></p-skeleton>
  <p-skeleton styleClass="mb-2" width="10rem"></p-skeleton>
  <p-skeleton styleClass="mb-2" width="10rem"></p-skeleton>
</div>
<div class="h-full flex flex-column" *ngIf="!isDataLoading && translation">
  <div class="page-title mb-5 flex-grow-0">{{ translation.title }}</div>

  <div class="grid mb-5 flex-grow-0">
    <div class="col-12 md:col-4 lg:col-3">
      <b translate>show-translation.date</b> {{ translation.createdAt|amTimeAgo }}<br/>
      <b translate>show-translation.destination</b> {{ translation.destination }}<br/>
    </div>
    <div class="col-12 md:col-4  lg:col-3">
      <b translate>show-translation.context</b> {{ translation.context }}
    </div>
    <div class="col-12 md:col-4  lg:col-6 flex justify-content-end">
      <p-button [label]="'show-translation.update'|translate"
                styleClass="p-button-sm p-button-rounded mr-2"
                (click)="editing=true"
                *ngIf="translation.status === 'status.editing'"></p-button>
      <ng-container [ngSwitch]="translation.status">
        <p-button [label]="'show-translation.btn-ask-quote'|translate"
                  styleClass="p-button-sm p-button-rounded mr-2"
                  (click)="askQuote()"
                  *ngSwitchCase="'status.editing'"></p-button>

        <p-button [label]="'show-translation.btn-statusdevis'|translate"
                  styleClass="p-button-sm p-button-rounded mr-2"
                  [disabled]="true"
                  *ngSwitchCase="'status.devis'"></p-button>

        <p-button [label]="('show-translation.btn-pay'|translate) + translation.price + '€'"
                  styleClass="p-button-sm p-button-rounded mr-2"
                  (click)="pay()"
                  *ngSwitchCase="'status.topay'"></p-button>


      </ng-container>

    </div>
  </div>
  <div class="grid flex-grow-1">
    <ng-container *ngFor="let p of spaned">
      <div class="col-12 md:col-6 px-2 flex flex-column">
        <div class="translation-main-title flex-grow-0 mb-3">
          <ng-container *ngIf="!p.original && translation.translated">
            <p-button (click)="download()" icon="pi pi-cloud-download"
                      styleClass="p-button-sm p-button-text"></p-button>
          </ng-container>
          <span (click)="log(p)">{{ p.title|translate }}</span>
        </div>
        <div class="h-full relative">
          <ng-container *ngIf="p.original && translation.isImport">
            <div class="translation-content absolute left-0 right-0 top-0 bottom-0 overflow-scroll p-0">
              <app-tr-viewer [translation]="translation"></app-tr-viewer>
            </div>
          </ng-container>
          <ng-container *ngIf="p.original && !translation.isImport">
            <div class="translation-content absolute left-0 right-0 top-0 bottom-0 overflow-scroll"
                 [innerHTML]="p.content"></div>
          </ng-container>
          <ng-container *ngIf="!p.original">
            <div class="translation-content absolute left-0 right-0 top-0 bottom-0 overflow-scroll p-0">
              <app-tr-viewer [translated]="true" [translation]="translation" *ngIf="p.content"></app-tr-viewer>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <p-sidebar [(visible)]="editing" position="right"
             styleClass="sidebar-translation {{ fullSize ? 'sidebar-translation-full' : '' }}"
             [showCloseIcon]="false">
    <ng-template pTemplate="header"></ng-template>
    <div class="flex flex-column h-full">
      <div class="flex-grow-1">
        <app-translation-crud [translation]="translation" (cancel)="editing = false"
                              [(fullsize)]="fullSize"
                              (saved)="translationSaved($event)"></app-translation-crud>
      </div>
    </div>
  </p-sidebar>
</div>
<p-confirmDialog [style]="{ width: '50vw' }" key="positionDialog"
                 rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  public static events: any = {};

  constructor() {
  }

  subscribe(evt: string, cb: any) {
    if (!EventsService.events[evt]) {
      EventsService.events[evt] = [];
    }
    EventsService.events[evt].push(cb);
  }

  clear(evt: string) {
    if (!EventsService.events[evt]) {
      return;
    }
    delete EventsService.events[evt];
  }

  publish(evt: string, args?: any) {
    if (!EventsService.events[evt]) {
      return;
    }
    for (let i = 0; i < EventsService.events[evt].length; i++) {
      EventsService.events[evt][i](args);
    }
  }
}

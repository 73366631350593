import {Component, OnDestroy, OnInit} from '@angular/core';
import {PaymentService} from '../../services/payment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentCheckModel} from '../../models/pay/payment-check.model';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {

  isLoading: boolean = true;
  status: string = '';

  interval: any = null;

  constructor(private readonly paymentService: PaymentService,
              private readonly router: Router,
              private readonly activeRoute: ActivatedRoute) {
  }

  async ngOnInit() {
    this.status = this.activeRoute.snapshot.params['status'];

    if (this.status !== 'success') {
      return;
    }

    this.isLoading = true;

    this.interval = setInterval(async () => {
      const result: PaymentCheckModel = await this.paymentService.checkPayment(this.activeRoute.snapshot.params['id']);
      if (result.status === 'complete') {
        debugger;
        this.isLoading = false;
        this.status = result.status;
        clearInterval(this.interval);
      }
    }, 2000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }


  back() {
    this.router.navigate(['/' + this.activeRoute.snapshot.params['id']]);
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslationModel} from '../../models/translation.model';
import {ApiService} from '../../services/api.service';
import {environment} from '../../../environments/environment';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {PaymentLinkModel} from '../../models/pay/payment-link.model';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthService} from '../../services/auth.service';
import {AccountModel} from '../../models/auth/account.model';


@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit {

  isDataLoading: boolean = true;

  translation: TranslationModel | null = null;

  spaned: any[] = [];

  editing: boolean = false;

  isLoading: boolean = false;

  fullSize: boolean = false;

  constructor(private readonly router: Router,
              private readonly activeRoute: ActivatedRoute,
              private readonly api: ApiService,
              private readonly confirmationService: ConfirmationService,
              private readonly translate: TranslateService,
              private readonly sanitizer: DomSanitizer,
              private readonly authService: AuthService) {
  }

  async ngOnInit() {
    this.translation = await this.api.get(environment.baseApi + '/api/auth/translation/' + this.activeRoute.snapshot.params['id'], {
      class: TranslationModel,
      isArray: false
    });

    this.spaned = [
      {
        title: 'show-translation.my-translation',
        content: this.sanitizer.bypassSecurityTrustHtml(this.translation?.content || ''),
        original: true,
      },
      {
        title: 'show-translation.translated',
        content: this.translation?.translated || '',
        original: false,
      },
    ];
    this.isDataLoading = false;
  }

  back() {
    this.router.navigate(['/']);
  }

  translationSaved(translation: TranslationModel) {
    this.editing = false;

    this.translation = translation;
    this.spaned = [
      {
        title: 'show-translation.my-translation',
        content: this.sanitizer.bypassSecurityTrustHtml(this.translation?.content || ''),
        original: true,
      },
      {
        title: 'show-translation.translated',
        content: this.translation?.translated || '',
        original: false,
      },
    ];
  }


  async askQuote() {
    const canContinue = await this.checkProfile();

    if (!canContinue) {
      return;
    }
    this.confirmationService.confirm({
      message: this.translate.instant('translation.confirmation.askquote.content'),
      header: this.translate.instant('translation.confirmation.askquote.title'),
      icon: 'pi pi-info-circle',
      acceptLabel: this.translate.instant('shared.yes'),
      rejectLabel: this.translate.instant('shared.cancel'),
      accept: async () => {
        this.isLoading = true;
        this.translation = await this.api.post(environment.baseApi + '/api/auth/translation-action/' + this.translation?.id + '/askquote', {}, {
          class: TranslationModel,
          isArray: false
        });
        this.isLoading = false;
      },
      key: 'positionDialog'
    });
  }

  async pay() {
    this.isLoading = true;
    const paymentLink: PaymentLinkModel = await this.api.post(environment.baseApi + '/api/auth/payment/init/' + this.translation?.id, {}, {
      class: PaymentLinkModel
    });
    window.location.href = paymentLink.link;
  }


  async download() {
    const result = await this.api.download(environment.baseApi + '/api/auth/translation-action/' + this.translation?.id + '/download');
    const blob = new Blob([result]);
    const fileUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = this.translation?.title + '-' + this.translation?.destination + '-traduit.docx';
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(fileUrl);
  }

  logMe(e: any) {
    console.log(e);
  };

  checkProfile(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const user: AccountModel = await this.authService.getAccount();
      if (user.email !== user.name && user.address && user.zip && user.town) {
        return resolve(true);
      }
      this.confirmationService.confirm({
        message: this.translate.instant('account.complete-profile.content'),
        header: this.translate.instant('account.complete-profile.title'),
        icon: 'pi pi-info-circle',
        acceptLabel: this.translate.instant('shared.yes'),
        rejectLabel: this.translate.instant('shared.cancel'),
        accept: async () => {
          this.router.navigate(['/account'])
          resolve(false);
        },
        reject: () => {
          resolve(false);
        },
        key: 'positionDialog'
      });
    });
  }

  log(data: any) {
    console.log(data);
  }
}

<div class="flex flex-column h-full w-full">
  <div class="flex">
    <p-button icon="pi {{ fullsize ? 'pi-arrow-right' : 'pi-arrow-left' }}" (click)="toggleSize()"
              styleClass="p-button-text p-button-sm text-white mr-3"></p-button>

    <div class="flex-grow-1">
      <div class="title-2" translate *ngIf="!_translation.id">translation.title-create</div>
      <div class="title-2" translate *ngIf="_translation.id">translation.title-update</div>
      <div class="mt-4 mb-4"></div>
    </div>
  </div>
  <div class="flex-grow-0" *ngIf="!fullsize">
    <span class="p-float-label p-inputwrapper-filled">
      <input id="title" type="text" [disabled]="isLoading" class="p-filled w-full" pInputText name="title"
             [(ngModel)]="_translation.title"/>
      <label for="title" translate>translation.title</label>
    </span>
    <div class="mt-4 mb-4"></div>
    <span class="p-float-label p-inputwrapper-filled">
      <input id="context" type="text" class="p-filled w-full" pInputText name="context"
             [(ngModel)]="_translation.context" [disabled]="isLoading"/>
      <label for="context" translate>translation.context</label>
    </span>
    <div class="mt-4 mb-4"></div>

    <span class="p-float-label">
      <p-dropdown [options]="destinations" id="destination" name="destination" styleClass="w-full"
                  [(ngModel)]="_translation.destination" [disabled]="isLoading"
                  optionLabel="native" optionValue="native"
                  [filter]="true" filterBy="native"></p-dropdown>
      <label for="destination" translate>translation.destination</label>
    </span>
    <div class="mt-4 mb-4"></div>
    <ng-container *ngIf="_translation.id">
      <p-fileUpload mode="basic"
                    [disabled]="isImporting"
                    chooseLabel="Importer"
                    name="file"
                    [customUpload]="false"
                    (onUpload)="setFile($event)"
                    (onError)="setFile($event)"
                    [url]="toolsImportUrl"
                    (onBeforeUpload)="isImporting=true"
                    [headers]="getHeadersForImport()"
                    [multiple]="false" [maxFileSize]="1000000" [auto]="true">
        <ng-template pTemplate="toolbar">
        </ng-template>
      </p-fileUpload>
      <div class="mt-4 mb-4"></div>
    </ng-container>
  </div>
  <ng-container *ngIf="_translation.id && !_translation.isImport">
    <div class="flex-grow-1 rich-editor-wrapper">
      <textarea class="h-full" [(ngModel)]="_translation.content" [ngxSummernote]="config"></textarea>
    </div>
  </ng-container>
  <ng-container *ngIf="_translation.isImport">
    <div class="flex-grow-1 rich-editor-wrapper">
      <div class="flex">
        <div class="flex-grow-1">
          <img src="assets/images/doc.png" class="w-2rem"/>
          <p-button [disabled]="isImporting"
                    (click)="downloadFile()" [label]="_translation.originalName" styleClass="p-button-text"
                    icon=""></p-button>
        </div>
        <div class="flex-grow-0">
          <p-button icon="pi pi-times" styleClass="p-button-sm p-button-rounded p-button-danger p-button-text"
                    (click)="removeFile()"></p-button>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="flex-grow-0 flex align-content-center justify-content-end">
    <p-button [label]="'translation.btn-back'|translate" styleClass="p-button-text mr-3" (click)="close()"></p-button>
    <p-button [label]="(_translation.id ? 'translation.btn-update' : 'translation.btn-create')|translate"
              styleClass="p-button-sm p-button-rounded" [icon]="isLoading ? 'pi pi-spin pi-spinner' : ''"
              (click)="onSubmit()"
    ></p-button>
  </div>
</div>


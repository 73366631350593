import {Expose} from 'class-transformer';

export class AuthTokenModel {
  _token: string = '';

  username: string = '';

  displayName: string = '';

  set token(value: string) {
    this._token = value;
    const tokenData = JSON.parse(atob(value.split('.')[1]));

    this.username = tokenData.username;
    this.displayName = tokenData.client?.name || this.username;
  }

  get token() {
    return this._token;
  }

  get initiales() {
    const spl = this.displayName.split(' ');
    try {
      return spl[0][0] + (spl[1][0] || '');
    } catch (e) {
      return spl[0][0];
    }
  }


}

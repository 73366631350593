import {Component} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  userLogin: { username: string, password: string } = {
    username: '',
    password: '',
  }

  constructor(private readonly authService: AuthService,
              private readonly translateService: TranslateService,
              private readonly messageService: MessageService,
              private readonly router: Router) {
  }

  async connect() {
    try {
      await this.authService.connect(this.userLogin.username, this.userLogin.password);
      this.router.navigate(['/']);
    } catch (e) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error.title'),
        detail: this.translateService.instant('error.login-error')
      });
    }
  }
}

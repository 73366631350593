import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {StorageService} from './storage.service';
import {lastValueFrom} from 'rxjs';
import {plainToInstance} from 'class-transformer';
import {BaseErrorModel} from '../models/errors/base-error.model';
import {Router} from '@angular/router';
import {ApiService} from './api.service';
import {environment} from '../../environments/environment';
import {PaymentCheckModel} from '../models/pay/payment-check.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private readonly api: ApiService
  ) {
  }

  checkPayment(id: string): Promise<PaymentCheckModel> {
    return this.api.post(environment.baseApi + '/api/auth/payment/check/' + id, {}, {
      class: PaymentCheckModel,
      isArray: false
    });
  }

}

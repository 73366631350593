import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {AccountModel} from '../../models/auth/account.model';
import {instanceToInstance} from 'class-transformer';
import {ApiService} from '../../services/api.service';
import {environment} from '../../../environments/environment';
import {BillModel} from '../../models/pay/bill.model';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  user: AccountModel | any = null;
  userToEdit: AccountModel | any = null;

  isLoading: boolean = false;

  isEditing: boolean = false;

  fields: { label: string, key: string }[] = [
    {
      label: 'account.form.lastname',
      key: 'name'
    },
    {
      label: 'account.form.email',
      key: 'email'
    },
    {
      label: 'account.form.address',
      key: 'address'
    },
    {
      label: 'account.form.zip',
      key: 'zip'
    },
    {
      label: 'account.form.town',
      key: 'town'
    },
  ];

  bills: BillModel[] = [];

  constructor(private readonly router: Router,
              private readonly authService: AuthService,
              private readonly api: ApiService) {
  }

  async ngOnInit() {
    const results = await Promise.all([
      this.authService.getAccount(),
      this.api.get(environment.baseApi + '/api/auth/account/bills', {
        class: BillModel,
        isArray: true
      })
    ]);

    this.user = results[0]
    this.userToEdit = instanceToInstance(this.user);

    this.bills = results[1];
  }

  back() {
    this.router.navigate(['/']);
  }


  edit() {
    this.userToEdit = instanceToInstance(this.user);
    this.isEditing = true;
  }

  cancel() {
    this.isEditing = false;
    this.userToEdit = instanceToInstance(this.user);
  }

  async save() {
    this.isLoading = true;
    this.user = await this.authService.updateAccount(this.userToEdit);
    this.userToEdit = instanceToInstance(this.user);
    this.isEditing = false;
    this.isLoading = false;
  }

  async getBill(bill: BillModel) {
    const ret = await this.api.get(environment.baseApi + '/api/auth/account/bills/' + bill.notePrivate + '/' + bill.lastMainDoc);
    const binaryString = window.atob(ret.content);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Créer un Blob à partir des données binaires
    const blob = new Blob([bytes], {type: 'application/pdf'});

    // Créer un lien (a) élément pour le téléchargement
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = bill.ref + '.pdf';

    // Ajouter le lien au document, le cliquer, puis le retirer
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Libérer l'URL du Blob
    URL.revokeObjectURL(link.href);
  }
}

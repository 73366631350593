import {Component, Input} from '@angular/core';
import {TranslationModel} from '../../../../app/models/translation.model';
import {ApiService} from '../../../../app/services/api.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-tr-viewer',
  templateUrl: './tr-viewer.component.html',
  styleUrls: ['./tr-viewer.component.scss']
})
export class TrViewerComponent {

  image: any = null;


  isLoading: boolean = true;
  hasError: boolean = false;

  @Input() translated: boolean = false;

  constructor(private readonly api: ApiService) {
  }

  @Input()
  set translation(tr: TranslationModel) {
    this.getImage(tr);
  }


  async getImage(tr: TranslationModel) {
    try {
      const fileName: string = this.translated ? 'translated.png' : 'preview.png'
      const arrayBuffer = await this.api.download(environment.baseApi + '/api/auth/tools/preview/' + tr.id + '/' + fileName);
      const blob = new Blob([arrayBuffer], {type: 'image/png'});
      this.image = URL.createObjectURL(blob);
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.hasError = true;
    }
  }
}

import {Component} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {CreateAccountModel} from '../../../models/auth/create-account.model';
import {RegisterResultModel} from '../../../models/register-result.model';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent {
  userAccount: CreateAccountModel = new CreateAccountModel();

  registerResult: RegisterResultModel = new RegisterResultModel();


  isLoading: boolean = false;

  constructor(private readonly authService: AuthService,
              private readonly translateService: TranslateService,
              private readonly messageService: MessageService,
              private readonly router: Router) {
  }

  async register() {

    this.isLoading = true;
    try {
      this.registerResult = await this.authService.register(this.userAccount);
      if (this.registerResult.status) {
        this.router.navigate(['/auth/activation/' + this.userAccount.username]);
      }
    } catch (e) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error.title'),
        detail: this.translateService.instant('error.login-error')
      });
    }
    this.isLoading = false;
  }
}

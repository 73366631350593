<div class="min-h-screen flex relative lg:static surface-ground overflow-hidden"
     [ngClass]="{'has-sidebar-open': sidebarVisible, 'has-sidebar-close': !sidebarVisible}">
  <div class="flex flex-column sidebar px-2 py-2 overflow-hidden"
       [ngClass]="{'wide': sidebarVisible, 'narrow': !sidebarVisible}">

    <div class="flex-grow-0 text-right">
      <p-button icon="pi pi-bars" (click)="toggleSidebar()"
                styleClass="p-button-text p-button-sm text-white"></p-button>
    </div>
    <div class="mt-2 flex align-content-center flex-grow-0">
      <p-avatar [label]="currentUser?.initiales" size="large" styleClass="mr-3" shape="circle"
                [style]="{ 'background-color': 'var(--bleu)', color: 'var(--title-color)' }"></p-avatar>
      <div class="hide-on-closed username flex align-items-center">
        {{ currentUser?.displayName }}
      </div>
    </div>
    <div class="w-full mt-4 mb-4 border-bottom-1 surface-border"></div>
    <div class="flex-grow-1 relative">
      <div
        class="text-tips absolute left-0 top-0 right-0 bottom-0 hide-on-closed px-3 py-3 overflow-scroll border-round-xl">
        <ng-container *ngIf="randomQuote">
          <h2>{{ randomQuote.title }}</h2>
          <div>{{ randomQuote.content }}</div>
        </ng-container>
      </div>
    </div>
    <div class="flex-grow-0 mt-4">
      <ul class="list-none m-0 p-0">
        <li>
          <a (click)="router.navigate(['/account'])"
             class="flex align-items-center cursor-pointer p-3 border-round-xl text-700 hover:surface-50 transition-duration-150 transition-colors">
            <i class="pi pi-cog mr-2"></i>
            <span class="font-medium hide-on-closed" translate>layout.menu.account</span>
          </a>
        </li>
        <li>
          <a (click)="logout()"
             class="flex align-items-center cursor-pointer p-3 border-round-xl text-700 hover:surface-50 transition-duration-150 transition-colors">
            <i class="pi pi-times mr-2"></i>
            <span class="font-medium hide-on-closed" translate>layout.menu.logout</span>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="w-full background-dark px-4 pt-4 pb-8 h-screen overflow-auto relative">
    <router-outlet></router-outlet>
    <div class="footer py-3 px-2 flex">
      <div class="flex-grow-1">
        &copy; PolyglotConnect 2024
      </div>
      <div class="flex-grow-0">
        <p-dropdown [options]="availableLangs" [(ngModel)]="selectedLang" optionLabel="name"
                    (onChange)="changeLang()" styleClass="p-selector-small-selector">
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="selectedLang">
              <img [src]="selectedLang.flag"
                   style="width: 18px"/>
              <div>{{ selectedLang.name }}</div>
            </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
            <div class="flex align-items-center gap-2">
              <img [src]="country.flag"
                   style="width: 18px"/>
              <div>{{ country.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>

</div>


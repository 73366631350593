import {Injectable} from '@angular/core';
import {instanceToPlain, plainToInstance} from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
  }

  async setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(instanceToPlain(value)));
  }

  async getItem(key: string, model: any = null, defaultValue: any = null): Promise<any> {
    const value = localStorage.getItem(key);
    if (!value) {
      return defaultValue;
    }
    return model ? plainToInstance(model, JSON.parse(value)) : value;
  }

  getItemSync(key: string, defaultValue: any = null) {
    const value = localStorage.getItem(key);
    return !value ? defaultValue : JSON.parse(value);
  }

  clear() {
    localStorage.clear();
  }
}

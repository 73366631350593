<p-toast></p-toast>
<form (ngSubmit)="connect()">
  <div class="surface-card p-4 shadow-2 border-round w-full">
    <div class="text-center mb-5">
      <div class="text-900 text-3xl font-medium mb-3">{{'auth.title'|translate}}</div>
    </div>

    <div>
      <span class="p-float-label p-inputwrapper-filled mb-5">
        <input id="email1" name="email1" type="text" [placeholder]="'auth.email'|translate"
               [(ngModel)]="userLogin.username"
               pInputText class="w-full p-filled">
        <label for="email1" translate>auth.email</label>
      </span>
      <span class="p-float-label p-inputwrapper-filled mb-7">
        <input id="password1" name="password1" type="password" [placeholder]="'auth.password'|translate"
               [(ngModel)]="userLogin.password"
               pInputText class="w-full p-filled">
        <label for="email1" translate>auth.password</label>
      </span>
      <div class="flex justify-content-end">
        <p-button [label]="'auth.btn-login'|translate" type="submit"
                  styleClass="p-button-rounded" (click)="connect()">
        </p-button>
      </div>
      <div class="flex justify-content-center mt-5">
        <a [routerLink]="['/auth/create-account']" translate>auth.lnk-create-account</a>
      </div>

    </div>
  </div>
</form>

import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {environment} from '../../environments/environment';
import {AuthTokenModel} from '../models/auth/auth.token.model';
import {BehaviorSubject} from 'rxjs';
import {StorageService} from './storage.service';
import {AccountModel} from '../models/auth/account.model';
import {instanceToPlain} from 'class-transformer';
import {CreateAccountModel} from '../models/auth/create-account.model';
import {StatusModel} from '../models/status.model';
import {RegisterResultModel} from '../models/register-result.model';
import {RenewTokenModel} from '../models/auth/renew-token.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  currentUser$: BehaviorSubject<AuthTokenModel | null> = new BehaviorSubject<AuthTokenModel | null>(null);

  constructor(private readonly apiService: ApiService,
              private readonly storage: StorageService
  ) {
  }

  async connect(userName: string, password: string) {
    try {
      const token: AuthTokenModel = await this.apiService.post(environment.baseApi + '/api/login_check',
        {
          username: userName,
          password
        },
        {
          isArray: false,
          class: AuthTokenModel
        }
      );
      await this.storage.setItem('user_token', token.token);
      this.currentUser$.next(token);

      return token;
    } catch (e) {
      throw 'login-error';
    }
  }

  logout() {
    this.storage.clear();
  }

  async initUser() {
    const token = await this.storage.getItem('user_token');
    const user = new AuthTokenModel();
    user.token = token;
    this.currentUser$.next(user);
  }

  async renewToken() {
    const token: RenewTokenModel = await this.apiService.post(environment.baseApi + '/api/auth/account/renew-token', {}, {
      isArray: false,
      class: RenewTokenModel
    });

    await this.storage.setItem('user_token', token.token);
    await this.initUser();
  }

  getToken() {
    return '';
  }

  async getAccount() {
    return this.apiService.get(environment.baseApi + '/api/auth/account', {
      class: AccountModel,
      isArray: false
    });
  }

  async updateAccount(account: AccountModel) {
    const result = await this.apiService.post(environment.baseApi + '/api/auth/account', instanceToPlain(account), {
      class: AccountModel,
      isArray: false
    });

    await this.renewToken();

    return result;
  }

  async register(account: CreateAccountModel): Promise<RegisterResultModel> {
    try {
      const result: RegisterResultModel = await this.apiService.post(
        environment.baseApi + '/api/register',
        account,
        {
          isArray: false,
          class: RegisterResultModel
        }
      );
      console.log(result);
      return result;
    } catch (e) {
      throw 'login-error';
    }
  }

  async activate(activate: { code: string, email: string }): Promise<StatusModel> {
    try {
      const result: RegisterResultModel = await this.apiService.post(
        environment.baseApi + '/api/register/activate',
        activate,
        {
          isArray: false,
          class: StatusModel
        }
      );
      console.log(result);
      return result;
    } catch (e) {
      throw 'login-error';
    }
  }


}

import {Transform, Type} from 'class-transformer';
import * as moment from 'moment';
import {Moment} from 'moment';

export class BillModel {
  ref: string = '';
  ref_customer: string = '';
  lastMainDoc: string = '';
  status: string = '';
  notePrivate: string = '';

  @Type(() => Date)
  @Transform(({value}) => moment.unix(value), {toClassOnly: true})
  dateValidation: Moment | null = null;
}

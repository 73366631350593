import {Component, OnInit} from '@angular/core';
import {AuthTokenModel} from '../../models/auth/auth.token.model';
import {AuthService} from '../../services/auth.service';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {QuoteModel} from '../../models/auth/quote.model';
import {EventsService} from '../../services/events.service';
import {Utils} from '../../utils';
import {StorageService} from '../../services/storage.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  sidebarVisible: boolean = true;

  currentUser: AuthTokenModel | null = null;

  randomQuote: QuoteModel | null = null;

  availableLangs = Utils.availableLangs;

  selectedLang: any = null;

  constructor(private readonly authService: AuthService,
              private readonly dataService: DataService,
              public readonly router: Router,
              private readonly evtService: EventsService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly storage: StorageService,
              private readonly translate: TranslateService) {
  }

  async ngOnInit() {
    this.router.events.subscribe((evt: any) => {
      if (evt.routerEvent instanceof NavigationEnd) {
        if (this.activatedRoute.firstChild?.snapshot.data['hideMenu'] === true) {
          this.sidebarVisible = false;
        } else if (this.activatedRoute.firstChild?.snapshot.data['hideMenu'] === false) {
          this.sidebarVisible = true;
        }
      }
    });
    this.authService.initUser();
    this.authService.currentUser$.subscribe((user: AuthTokenModel | null) => {
      this.currentUser = user;
    });
    await this.dataService.initData();
    this.randomQuote = await this.dataService.getRandomQuote();
    setInterval(async () => {
      this.randomQuote = await this.dataService.getRandomQuote();
    }, 1000 * 60);


    this.evtService.subscribe('layout:menu:close', () => {
      this.sidebarVisible = false;
    });
    this.evtService.subscribe('layout::menu:open', () => {
      this.sidebarVisible = true;
    });
    this.selectedLang = await this.storage.getItemSync('language', Utils.availableLangs[0]);

  }

  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
  }


  logout() {
    this.authService.logout();
    this.router.navigate(['/auth/login'])
  }


  changeLang() {
    console.log('updated', this.selectedLang);
    this.storage.setItem('language', this.selectedLang);
    this.translate.setDefaultLang(this.selectedLang.code);
  }
}

<ng-container *ngIf="isLoading">
  <p-progressSpinner></p-progressSpinner>
</ng-container>
<ng-container *ngIf="!isLoading && !hasError">
  <img style="width:100%" [src]="image"/>
</ng-container>
<ng-container *ngIf="!isLoading && hasError">
  <p class="mt-5 ml-5" style="color:red" translate>tr-viewer.error</p>
</ng-container>


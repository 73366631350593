import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslationModel} from '../../models/translation.model';
import {instanceToInstance, instanceToPlain, plainToInstance} from 'class-transformer';
import {environment} from '../../../environments/environment';
import {ApiService} from '../../services/api.service';
import {DialogService} from 'primeng/dynamicdialog';
import {ErrorDialogComponent} from '../error-dialog/error-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from '../../services/data.service';
import {HttpHeaders} from '@angular/common/http';
import {StorageService} from '../../services/storage.service';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-translation-crud',
  templateUrl: './translation-crud.component.html',
  styleUrls: ['./translation-crud.component.scss']
})
export class TranslationCrudComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;
  _translation: TranslationModel = new TranslationModel();

  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() saved: EventEmitter<TranslationModel> = new EventEmitter<TranslationModel>();

  @Input() fullsize: boolean = false;
  @Output() fullsizeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  set translation(tr: TranslationModel) {
    this._translation = instanceToInstance(tr);
    this.toolsImportUrl = environment.baseApi + '/api/auth/tools/import/' + this._translation.id;
  }

  toolsImportUrl: string = environment.baseApi + '/api/auth/tools/import/';

  destinations: any[] = [];

  isImporting: boolean = false;

  config: any = {
    placeholder: '',
    tabsize: 2,
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'hr']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  }

  constructor(private readonly api: ApiService,
              private readonly dialogService: DialogService,
              private readonly translateService: TranslateService,
              private readonly dataService: DataService,
              private readonly storage: StorageService,
              private readonly confirmService: ConfirmationService) {
  }

  async ngOnInit() {
    this.destinations = await this.dataService.getData('countries');
    if (this.destinations[0]['native'] !== '--') {
      this.destinations.unshift({
        native: '--'
      });
    }
  }

  ngOnDestroy(): void {
  }

  async onSubmit() {
    this.isLoading = true;
    try {
      const url = environment.baseApi + '/api/auth/translation' + (this._translation.id ? '/' + this._translation.id : '');
      const tr = await this.api.post(url, instanceToPlain(this._translation), {
        class: TranslationModel,
        isArray: false
      });
      this.saved.emit(tr);
    } catch (e) {
      this.dialogService.open(ErrorDialogComponent, {
        header: this.translateService.instant('error.title'),
        width: '70%',
        contentStyle: {"max-height": "350px", "overflow": "auto"}
      })
    }

    this.isLoading = false;
  }

  close() {
    this.cancel.emit();
  }

  toggleSize() {
    console.log('TOGGLE')
    this.fullsize = !this.fullsize;
    this.fullsizeChange.emit(this.fullsize);
  }

  async setFile(evt: any) {
    this._translation = plainToInstance(TranslationModel, evt.originalEvent.body.data);
    this.isImporting = false;
  }


  fileToArrayBuffer(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  getHeadersForImport() {
    let headers = new HttpHeaders();

    const token = this.storage.getItemSync('user_token');
    headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return headers;
  }

  removeFile() {
    this.confirmService.confirm({
      message: this.translateService.instant('translation.import.remove.content'),
      header: this.translateService.instant('translation.import.remove.title'),
      icon: 'pi pi-info-circle',
      acceptLabel: this.translateService.instant('shared.yes'),
      rejectLabel: this.translateService.instant('shared.cancel'),
      accept: async () => {
        this.isLoading = true;
        this._translation = await this.api.delete(this.toolsImportUrl, {
          isArray: false,
          class: TranslationModel
        });
        this.isLoading = false;
      },
      key: 'positionDialog'
    });
  }

  async downloadFile() {
    this.isImporting = true;
    const result = await this.api.download(this.toolsImportUrl + '/download');
    const blob = new Blob([result]);

    const fileUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = this._translation.originalName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(fileUrl);
    this.isImporting = false;
  }
}

import {Component, OnInit} from '@angular/core';
import {TranslationModel} from '../../models/translation.model';
import {Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {lastValueFrom} from 'rxjs';

interface StatusInt {
  name: string,
  code: string
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  newSidebar = false;

  translation = new TranslationModel();

  translations: TranslationModel[] = [];
  translationsFiltered: TranslationModel[] = [];

  nbTradsEnCours: number = 0;

  fullSize: boolean = false;

  statuses: StatusInt[] = [];
  selectedStatuses: StatusInt[] = [];
  searchText: string = '';

  constructor(private readonly router: Router,
              private readonly api: ApiService,
              private readonly translate: TranslateService) {

  }

  async ngOnInit() {

    const statusTranslated = await lastValueFrom(this.translate.get(['translation.status.editing', 'translation.status.devis', 'translation.status.topay', 'translation.status.processing', 'translation.status.translated']));
    for (let k in statusTranslated) {
      this.statuses.push({
        code: k.replace('translation.', ''),
        name: statusTranslated[k]
      });
    }

    this.translations = await this.api.get(environment.baseApi + '/api/auth/translation', {
      class: TranslationModel,
      isArray: true
    });
    this.updateSearch();
    this.nbTradsEnCours = this.translations.filter(tr => tr.status !== 'status.translated').length;
  }

  createNew() {
    this.translation = new TranslationModel();
    this.newSidebar = true;
  }

  translationCreated(translation: TranslationModel) {
    this.router.navigate(['/' + translation.id]);
  }

  goTo(translation: TranslationModel) {
    this.router.navigate(['/' + translation.id]);
  }


  updateSearch() {
    this.translationsFiltered = this.translations.filter((t: TranslationModel) => {
      if (this.selectedStatuses.length > 0) {
        let found = false;
        this.selectedStatuses.forEach((st) => {
          if (st.code === t.status) {
            found = true;
          }
        });
        if (!found) {
          return false;
        }
      }
      if (this.searchText && t.title.toLowerCase().indexOf(this.searchText.toLowerCase()) < 0) {
        return false;
      }

      return true;
    });
  }
}

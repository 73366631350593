<p-toast></p-toast>
<form>
  <div class="surface-card p-4 shadow-2 border-round w-full">
    <div class="text-center mb-5">
      <div class="text-900 text-3xl font-medium mb-3">{{'auth.create.title'|translate}}</div>
    </div>

    <div>
      <span class="p-float-label p-inputwrapper-filled mb-5">
        <input id="email1" [disabled]="isLoading" name="email1" type="text" [placeholder]="'auth.email'|translate"
               [(ngModel)]="userAccount.username"
               pInputText class="w-full p-filled">
        <label for="email1" translate>auth.email</label>
        <small class="text-danger" translate
               *ngIf="registerResult.errors.email">{{registerResult.errors.email}}</small>
      </span>
      <span class="p-float-label p-inputwrapper-filled mb-5">
        <p-password [disabled]="isLoading" id="password1" name="password1" [(ngModel)]="userAccount.password"
                    [toggleMask]="true"
                    styleClass="custom-password w-full"></p-password>
        <label for="password1" translate>auth.password</label>
        <small class="text-danger" translate
               *ngIf="registerResult.errors.password">{{registerResult.errors.password}}</small>
      </span>
      <span class="p-float-label p-inputwrapper-filled mb-5">
        <p-password [disabled]="isLoading" id="password2" name="password2" [(ngModel)]="userAccount.repeatPassword"
                    [toggleMask]="true"
                    styleClass="custom-password w-full"></p-password>
        <label for="password2" translate>auth.password-repeat</label>
        <small class="text-danger" translate
               *ngIf="registerResult.errors.repeat">{{registerResult.errors.repeat}}</small>
      </span>
      <div class="flex justify-content-end">
        <p-button [label]="'auth.btn-login'|translate" type="submit"
                  [disabled]="isLoading"
                  styleClass="p-button-rounded" (click)="register()">
        </p-button>
      </div>

    </div>
  </div>
</form>

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {StorageService} from './storage.service';
import {lastValueFrom} from 'rxjs';
import {plainToInstance} from 'class-transformer';
import {BaseErrorModel} from '../models/errors/base-error.model';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private readonly httpClient: HttpClient,
              private readonly storage: StorageService,
              private readonly router: Router,
  ) {
  }


  async post(path: string, params: any = {}, mappingData: {
    class: any,
    isArray?: boolean
  }): Promise<any> {

    let headers = new HttpHeaders();

    if (path.indexOf('/api/auth/') > -1) {
      const token = this.storage.getItemSync('user_token');
      headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    }

    try {
      const result: any = await lastValueFrom(this.httpClient.post(
        path,
        params,
        {
          headers
        }
      ));

      const dataToMap = result.data || result;

      return mappingData.isArray ? dataToMap.map((value: any) => plainToInstance(mappingData.class, value)) : plainToInstance(mappingData.class, dataToMap);
    } catch (e: any) {
      if (e.status === 401) {
        this.router.navigate(['/auth/login']);
      } else {
        throw plainToInstance(BaseErrorModel, e.error);
      }
    }
  }

  async get(path: string, mappingData: {
    class: any,
    isArray: boolean
  } | null = null): Promise<any> {

    let headers = new HttpHeaders();

    if (path.indexOf('/api/auth/') > -1) {
      const token = this.storage.getItemSync('user_token');
      headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    }

    try {
      const result: any = await lastValueFrom(this.httpClient.get(
        path,
        {
          headers
        }
      ));

      const dataToMap = result.data || result;

      if (!mappingData) {
        return dataToMap;
      }

      return mappingData.isArray ? dataToMap.map((value: any) => plainToInstance(mappingData.class, value)) : plainToInstance(mappingData.class, dataToMap);
    } catch (e: any) {
      if (e.status === 401) {
        this.router.navigate(['/auth/login']);
      } else {
        throw plainToInstance(BaseErrorModel, e.error);
      }
    }
  }

  async delete(path: string, mappingData: {
    class: any,
    isArray: boolean
  } | null = null): Promise<any> {

    let headers = new HttpHeaders();

    if (path.indexOf('/api/auth/') > -1) {
      const token = this.storage.getItemSync('user_token');
      headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    }

    try {
      const result: any = await lastValueFrom(this.httpClient.delete(
        path,
        {
          headers
        }
      ));

      const dataToMap = result.data || result;

      if (!mappingData) {
        return dataToMap;
      }

      return mappingData.isArray ? dataToMap.map((value: any) => plainToInstance(mappingData.class, value)) : plainToInstance(mappingData.class, dataToMap);
    } catch (e: any) {
      if (e.status === 401) {
        this.router.navigate(['/auth/login']);
      } else {
        throw plainToInstance(BaseErrorModel, e.error);
      }
    }
  }

  async download(path: string): Promise<any> {

    let headers = new HttpHeaders();

    if (path.indexOf('/api/auth/') > -1) {
      const token = this.storage.getItemSync('user_token');
      headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    }

    try {
      return lastValueFrom(this.httpClient.get(
        path,
        {
          responseType: 'arraybuffer',
          headers
        }
      ));
    } catch (e: any) {
      if (e.status === 401) {
        this.router.navigate(['/auth/login']);
      } else {
        throw plainToInstance(BaseErrorModel, e.error);
      }
    }
  }
}

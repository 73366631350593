<ng-container *ngIf="!isLoading">
  <p-button [label]="'payment.btn-back'|translate" icon="pi pi-arrow-left"
            styleClass="p-button-text mr-3" (click)="back()"></p-button>

</ng-container>

<div *ngIf="isLoading" class="h-full flex flex-wrap justify-content-center align-content-center">
  <p-progressSpinner></p-progressSpinner>
</div>

<div *ngIf="status === 'complete' && !isLoading">
  <div class="page-title mb-5" translate>payment.success.title</div>

  <p translate>payment.success.content</p>
</div>


<div *ngIf="!isLoading && status !== 'complete'">

  <div class="page-title mb-5" translate>payment.error.title</div>

  <p translate>payment.error.content</p>
</div>

import {Component} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {StatusModel} from '../../../models/status.model';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss']
})
export class ActivationComponent {
  activation: { code: string } = {
    code: ''
  };

  constructor(private readonly authService: AuthService,
              private readonly translateService: TranslateService,
              private readonly messageService: MessageService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router) {
  }

  async activate() {
    try {
      const status: StatusModel = await this.authService.activate({
        code: this.activation.code,
        email: this.activatedRoute.snapshot.params['email']
      });

      if (!status.status) {
        throw "bad";
      }
      this.router.navigate(['/auth/login']);
    } catch (e) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('error.title'),
        detail: this.translateService.instant('error.login-error')
      });
    }
  }
}

import {Expose} from 'class-transformer';

export class TranslationModel {
  id: string = '';

  @Expose()
  title: string = '';

  @Expose()
  destination: string = '';

  @Expose()
  context: string = '';

  @Expose()
  content: string = '';

  @Expose()
  type: string = 'custom';

  @Expose()
  fileToUpload: string = '';


  originalName: string = '';
  isImport: boolean = false;

  translated: string = '';

  price: number = 0;
  status: string = '';

  createdAt: any;
}
